import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import api from 'api';
import get from 'lodash.get';
import UserModel from 'models/UserModel';
import { Icon } from 'modules/dashboard/components/styles';
import { Border } from 'modules/new-applications/components/application-sections/LimitAndApprovals/RevertDecision/Form/styles';
import commonStyles from 'modules/new-applications/css/common.css';
import FixedContent from 'modules/shared/components/containers/FixedContent';
import GridContent from 'modules/shared/components/containers/GridContent';
import Button from 'modules/shared/components/inputs/Button';
import RHFBorderedTextArea from 'modules/shared/components/v2/ReactHookForm/RHFBorderedTextArea';
import PopperTooltip from 'modules/shared/components/widgets/interactive/PopperToolTip.js';
import useYupValidationResolver from 'modules/shared/hooks/useYupValidationResolver';
import React, { ReactElement } from 'react';
import { useForm } from 'react-hook-form-latest';
import * as yup from 'yup';

const validationSchema = () =>
  yup.object().shape({
    revertDecisionNotes: yup
      .string()
      .required('Please enter notes for reverting decision.'),
  });

const getErrorMessage = (error) => {
  const errorCode = get(error, 'response.data.errors', [{}])[0].code;

  let errorMessage = '';

  switch (errorCode) {
    case 'too_old':
    case 'wrong_status':
    case 'archived':
      errorMessage =
        'The application should be on going the approval process, accepted or declined within 30 days to be reverted.';
      break;
    case 'autodecisioning':
      errorMessage = '1CAD approved application can not be reverted.';
      break;
    default:
      errorMessage =
        'Something went wrong. Please refresh your screen and try again.';
  }

  return errorMessage;
};

const Form = ({
  application,
  currentUser,
  onCloseForm,
  onRevertDecisionCallback,
  onSetAlert,
}: {
  application: any;
  currentUser: UserModel;
  onCloseForm: () => void;
  onRevertDecisionCallback: (callback?: () => void) => void;
  onSetAlert: (options?: any) => void;
}): ReactElement => {
  const { control, handleSubmit } = useForm({
    defaultValues: { revertDecisionNotes: '' },
    mode: 'onSubmit',
    resolver: useYupValidationResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    const applicationAPI = api(
      'applications',
      currentUser.accessToken,
      get(currentUser, 'currentEntity.id')
    );

    try {
      await applicationAPI.revertDecisions(
        application.id,
        data.revertDecisionNotes
      );

      onRevertDecisionCallback(() => onCloseForm());
    } catch (e) {
      console.error(e);
      onSetAlert({ message: getErrorMessage(e), type: 'error' });
      onCloseForm();
    }
  };

  return (
    <>
      <Border />
      <FixedContent
        header={
          <span>
            Revert decision{' '}
            <PopperTooltip
              title="Once done, the application will revert back to pending status. The history of previous decisions will remain."
              placement="right-start"
            >
              <Icon>
                <FontAwesomeIcon icon={['fas', 'question-circle']} />
              </Icon>
            </PopperTooltip>
          </span>
        }
        withBottomMargin={false}
      >
        <GridContent gridColumnTemplate="two_thirds">
          <form onSubmit={handleSubmit(onSubmit)}>
            <RHFBorderedTextArea
              control={control}
              label="Notes"
              name="revertDecisionNotes"
              placeholder="Notes"
            />
            <div className={commonStyles.buttons}>
              <Button text="Revert decision" type="submit" />
              <Button
                white
                text="Cancel"
                type="button"
                handleClick={onCloseForm}
              />
            </div>
          </form>
        </GridContent>
      </FixedContent>
    </>
  );
};

export default Form;
