import React, { ReactElement } from 'react';
import Button from 'modules/shared/components/inputs/Button';
import AuthorisationRequestModel from 'models/AuthorisationRequestModel';

const SubmitButton = ({
  authorisationRequest,
}: {
  authorisationRequest: AuthorisationRequestModel;
}): ReactElement => {
  let buttonText = 'Request access';
  if (authorisationRequest.status === 'request_sent') {
    buttonText = 'Resend request';
  }

  return (
    <div className="control">
      <Button text={buttonText} type="submit" />
    </div>
  );
};

export default SubmitButton;
