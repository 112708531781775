// tslint:disable:max-lines
import EntityModel from 'models/EntityModel';
import ModuleCardholderApplicationFlowModel from 'models/ModuleCardholderApplicationFlowModel';
import styles from 'modules/card-management-onboarding/css/CardManagementOnBoarding.css';
import ApplicationOverlay from 'modules/shared/components/top/ApplicationOverlay';
import Loader from 'modules/shared/components/widgets/static/Loader';
import React, { useEffect, useState } from 'react';
import { browserHistory } from 'react-router';

import { componentFactory } from '../index';

const flow = [
  'email-verification',
  'details',
  'authorisation',
  'terms',
  'complete',
];

function useModuleCardManagementApplicationRecord() {
  const [cardManagementApplication, setCardManagementApplication] = useState(
    new ModuleCardholderApplicationFlowModel()
  );

  return { cardManagementApplication, setCardManagementApplication };
}

function useSupplierRecord(supplierId) {
  const [supplier, setSupplier] = useState(new EntityModel({ id: supplierId }));

  return { setSupplier, supplier };
}

export default function CardManagementOnBoarding(props) {
  const { params, location } = props;

  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [showLoading, setShowLoading] = useState(false);
  const [applicant, setApplicant] = useState({});

  const {
    cardManagementApplication,
    setCardManagementApplication,
  } = useModuleCardManagementApplicationRecord();
  const { supplier, setSupplier } = useSupplierRecord(params.supplier_id);

  const redirect = (sectionIndex) => {
    browserHistory.push({
      pathname: `/card-management/${params.supplier_id}/${flow[sectionIndex]}`,
      query: {
        ...(location.query || {}),
        code: cardManagementApplication.attributes.verification_code,
        email: cardManagementApplication.attributes.email,
      },
    });
  };

  const toNextSection = () => {
    setCurrentSectionIndex(currentSectionIndex + 1);
    redirect(currentSectionIndex + 1);
  };

  const toPrevSection = () => {
    setCurrentSectionIndex(currentSectionIndex - 1);
    redirect(currentSectionIndex - 1);
  };

  useEffect(() => {
    setShowLoading(true);
    (async () => {
      await supplier.getLogoAndTheme();
      const query = props.location.query;
      const { channel, code, email } = query;
      if (email && code) {
        const attributes = {
          email,
          verificationCode: code,
          channel,
        };

        cardManagementApplication.setAttributes(attributes);
        const result = await cardManagementApplication.validateCode();
        if (result.data.valid) {
          await cardManagementApplication.load(params.supplier_id);
          setCurrentSectionIndex(1);
          redirect(1);
        }
      }
      setShowLoading(false);
    })();
  }, []);

  const childComponentProps = {
    applicant,
    cardManagementApplication,
    location,
    setApplicant,
    supplierId: props.params.supplier_id,
    supplierLogoUrl: supplier.attributes.logo_url,
    supplierRegion: supplier.attributes.region,
    toNextSection,
    toPrevSection,
  };

  let childComponent = <Loader />;
  if (!showLoading) {
    if (supplier.attributes.cardholder_flow_enable) {
      childComponent = componentFactory(
        flow[currentSectionIndex],
        childComponentProps
      );
    } else {
      childComponent = (
        <div className={styles.disabled_hint}>
          <div className={styles.hint_text}>
            {'Cardholder form disabled by supplier'}
          </div>
        </div>
      );
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.application_content}>
        {[1, 2, 3].includes(currentSectionIndex) && (
          <div className={styles.controls}>
            <ApplicationOverlay
              trading_name={supplier.attributes.trading_name}
              logo_url={supplier.attributes.logo_url}
              label={'Card management'}
              color={'white'}
            />
          </div>
        )}
        {childComponent}
      </div>
    </div>
  );
}
