import get from 'lodash.get';
import AddonRuleModel from 'models/AddonRuleModel';
import ApplicationModel from 'models/ApplicationModel';
import LeadModel from 'models/LeadModel';
import BorderedAutocomplete from 'modules/shared/components/inputs/BorderedAutocomplete';
import BorderedTextArea from 'modules/shared/components/inputs/BorderedTextArea';
import Button from 'modules/shared/components/inputs/Button';
import React from 'react';

import { IApplicationApproverState } from './hooks/useIUFApproverState';

function IUFApprover({
  approvalNotes,
  currentUserIsSelectedApprover,
  error,
  formattedUsers,
  handleApproverChange,
  handleSubmit,
  isIUFApproved,
  isTagging,
  owner,
  savedApprover,
  selectedApprover,
  setApprovalNotes,
  iufAddonRule,
}: IApplicationApproverState & {
  owner: ApplicationModel | LeadModel;
  iufAddonRule: AddonRuleModel;
}) {
  if (isIUFApproved) {
    const iufApproval = get(owner, 'iufApproval');
    const histories = get(iufApproval, 'iufApprovalHistories');
    const notes = iufApproval.approvalNotes;
    const iufVersion = get(iufAddonRule, 'formattedRuleLabel');
    const lastHistoryRecord = histories[histories.length - 1];

    return (
      <div className="columns">
        <div className="column is-4">
          <hr />
          {lastHistoryRecord && lastHistoryRecord.formattedContent}
          {
            <>
              <br />
              {`Following ${iufVersion}`}
            </>
          }{' '}
          {notes ? (
            <>
              <br />
              {notes}
            </>
          ) : (
            'No notes.'
          )}
        </div>
      </div>
    );
  }

  const tagLabel = savedApprover ? 'Retag' : 'Tag';

  return (
    <form className="columns is-multiline">
      <div className="column is-6">
        <BorderedAutocomplete
          options={formattedUsers}
          textFieldProps={{
            error: Boolean(error),
            helperText: error,
            label: `${tagLabel} approver`,
            name: 'approver',
          }}
          onChange={handleApproverChange}
          getOptionLabel={(option) => option.label}
          value={selectedApprover}
          controlled
        />
      </div>
      <div className="column is-6">
        <Button
          disabled={isTagging}
          loading={isTagging}
          type="button"
          text={tagLabel}
          onClick={() => handleSubmit('tag')}
        />
      </div>
      {currentUserIsSelectedApprover && (
        <div className="column is-12">
          <div className="columns is-multiline">
            <div className="column is-6">
              <BorderedTextArea
                label="Approval notes"
                name="approval_notes"
                onChange={(e) => setApprovalNotes(e.target.value)}
                value={approvalNotes}
              />
            </div>
          </div>
        </div>
      )}
    </form>
  );
}

export default IUFApprover;
