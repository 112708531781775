/* Import libraries */
import React from 'react';
import styles from './css/FormBuilder.css';
import { connect } from 'react-redux';

import {
  removeFormBuilderValidationResults,
  setFormBuilderValidationResults,
} from 'modules/shared/actions';

import FormBuilderComponentGridWrapper from './FormBuilderComponentGridWrapper';

class ConsumerFromRender extends React.Component {
  constructor(props) {
    super(props);
    this.state = { validationFlags: [] };
  }

  componentVaildate(index, boolean) {
    const { dispatch, reduxKey } = this.props;
    const { validationFlags } = this.state;
    validationFlags[index] = boolean;
    this.setState({ validationFlags });
    dispatch(setFormBuilderValidationResults(reduxKey, validationFlags));
  }

  componentWillUnmount() {
    const { dispatch, reduxKey } = this.props;
    dispatch(removeFormBuilderValidationResults(reduxKey));
  }

  render() {
    const {
      answers,
      components,
      validationTrigger,
      onChange,
      pageIndex,
    } = this.props;
    const { validationFlags } = this.state;
    const elements = [];
    components.forEach((component, index) => {
      const answer = (answers || []).find((answer) => {
        if (component.field_description) {
          return answer.description === component.field_description;
        }
        return (
          answer.description_1 === component.field_description_1 &&
          answer.description_2 === component.field_description_2
        );
      });
      elements.push(
        <FormBuilderComponentGridWrapper
          key={`component-${pageIndex}-${index}`}
          name={`component-${pageIndex}-${index}`}
          formParams={component}
          onChange={(params) => {
            onChange && onChange(params);
          }}
          isValid={(boolean) => {
            this.componentVaildate(index, boolean);
          }}
          showError={validationTrigger && !validationFlags[index]}
          answer={answer}
        />
      );
    });

    return <div className={styles.row}>{elements}</div>;
  }
}

module.exports = connect((state, ownProps) => {
  return {
    pageIndex: ownProps.pageIndex || 0,
  };
})(ConsumerFromRender);
