/* import components */
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { Fragment } from 'react';
import { humanize } from 'utils/humanize';
import { muiTheme } from 'modules/shared/helpers/colorPalettes';
/* Import styles */
import styles from './css/Radio.css';

function convertValue(value) {
  if (['undefined', 'string'].includes(typeof value)) {
    return value;
  }

  return value ? 'Yes' : 'No';
}

function Radiobox(props) {
  const {
    defaultValue,
    disabled,
    display,
    error,
    handleChange,
    helper_text,
    hide_helper_text,
    id,
    label,
    radioList,
    value,
  } = props;

  const displayInRow = display === 'row';

  const list = [];
  radioList.forEach((v, index) => {
    const classes = [];

    if (displayInRow && index + 1 !== radioList.length) {
      classes.push('mb-4');
    }

    let radioProps = {
      control: <Radio />,
      className: classes.join(' '),
    };

    if (typeof v === 'object') {
      const { description, disabled: vDisabled, label, value } = v;

      radioProps = {
        ...radioProps,
        disabled: disabled || vDisabled,
        key: `${id}-${value}`,
        label: (
          <Fragment>
            <div className={displayInRow ? 'mb-2' : ''}>{label}</div>
            {description && <div>{description}</div>}
          </Fragment>
        ),
        value,
      };
    } else {
      radioProps = {
        ...radioProps,
        disabled,
        key: `${id}-${v}`,
        label: <div>{humanize(v)}</div>,
        value: v,
      };
    }

    list.push(<FormControlLabel {...radioProps} />);
  });

  return (
    <MuiThemeProvider theme={muiTheme()}>
      <div className={styles.material_container}>
        <FormControl
          error={error ? true : false}
          className={styles.material_form_control}
        >
          <FormLabel
            classes={{
              root: styles.material_label,
            }}
            htmlFor={id}
          >
            {label}
          </FormLabel>
          <RadioGroup
            aria-label={label}
            id={id}
            name={id}
            className={
              displayInRow ? styles.material_group_row : styles.material_group
            }
            defaultValue={defaultValue}
            value={convertValue(value)}
            onChange={handleChange}
          >
            {list}
          </RadioGroup>
        </FormControl>
        <FormHelperText
          id={`${id}-helper-text`}
          error={error ? true : false}
          className={styles.material_helper_text}
          style={
            error
              ? { fontSize: 10, fontWeight: 800 }
              : { fontSize: 10, fontWeight: 400 }
          }
        >
          {hide_helper_text ? '' : helper_text || error}
        </FormHelperText>
      </div>
    </MuiThemeProvider>
  );
}

export default Radiobox;
