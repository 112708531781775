import CodeInputField from 'modules/consumer-onboarding/v2/Verification/VerificationCode/CodeField/CodeInputField';
import {
  CodeBlock,
  CodeBlockShadow,
  CodeBlockError,
  Wrapper,
} from 'modules/consumer-onboarding/v2/Verification/VerificationCode/CodeField/styles';
import React, { ReactElement, useRef, useState } from 'react';

const generateCodeBlocks = ({
  codeLength,
  isFocused,
  verificationCode,
}): ReactElement[] => {
  const blocks = new Array(codeLength).fill(0);

  return blocks.map((_, index) => {
    const isSelected = verificationCode.length === index;
    const isFilled =
      verificationCode.length === codeLength && index === codeLength - 1;

    return (
      <CodeBlock key={`code-block-${index}`}>
        {verificationCode[index]}
        {(isSelected || isFilled) && isFocused && <CodeBlockShadow />}
      </CodeBlock>
    );
  });
};

const getCurrentIndex = ({ codeLength, verificationCode }) => {
  if (verificationCode.length < codeLength) {
    return verificationCode.length;
  }

  return codeLength - 1;
};

const useVerificationCodeState = ({
  clearErrors,
  defaultValue,
  fieldName,
  setValue,
}) => {
  const [verificationCode, setVerificationCode] = useState(defaultValue);

  const onSetVerificationCode = (value) => {
    setVerificationCode(value);
    setValue(fieldName, value);
    clearErrors(fieldName);
  };

  return { onSetVerificationCode, verificationCode };
};

// Reference: https://www.codedaily.io/tutorials/Create-a-Segmented-Auto-Moving-SMS-Code-Verification-Input-in-React
const CodeField = (props): ReactElement => {
  const {
    clearErrors,
    codeLength,
    error,
    setValue,
    trigger,
    verificationCodeRegister,
  } = props;
  const inputRef = useRef(null);

  const { verificationCode, onSetVerificationCode } = useVerificationCodeState({
    clearErrors,
    defaultValue: '',
    fieldName: verificationCodeRegister.name,
    setValue,
  });
  const [isFocused, setIsFocused] = useState(false);

  const currentIndex = getCurrentIndex({ codeLength, verificationCode });
  // @ts-ignore-next-line
  const onClick = () => inputRef.current!.focus();

  return (
    <>
      <Wrapper onClick={onClick}>
        {generateCodeBlocks({ codeLength, isFocused, verificationCode })}
        <CodeInputField
          codeLength={codeLength}
          currentIndex={currentIndex}
          inputRef={inputRef}
          onSetVerificationCode={onSetVerificationCode}
          setIsFocused={setIsFocused}
          trigger={trigger}
          verificationCode={verificationCode}
          verificationCodeRegister={verificationCodeRegister}
        />
      </Wrapper>
      {error && (
        <CodeBlockError className="has-text-danger">{error}</CodeBlockError>
      )}
    </>
  );
};

export default CodeField;
