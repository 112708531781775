import get from 'lodash.get';
import commonStyles from 'modules/card-management-shared/css/Section.css';
import AutoSuggest from 'modules/shared/components/widgets/interactive/AutoSuggest';
import OptionsDropdown from 'modules/shared/components/widgets/interactive/OptionsDropdown';
import React from 'react';

function AddressCountryDropdown(props) {
  const { addressRegion, onChange } = props;

  const regionOptions = [
    { label: 'Australia', value: 'AU' },
    { label: 'New Zealand', value: 'NZ' },
  ];

  return (
    <div className={commonStyles.col_4}>
      <OptionsDropdown
        id="addressRegion"
        name="addressRegion"
        handleChange={onChange}
        label="Region"
        value={addressRegion}
        required={true}
        options={regionOptions}
      />
    </div>
  );
}

function AddressPicker(props) {
  const {
    addressKeyword,
    addressRegion,
    deliveryAddressLoading,
    deliveryAddressOptions,
    errors,
    handleDeliveryAddressChange,
    handleDeliveryAddressClick,
    onChangeAddressRegion,
  } = props;

  return (
    <div style={{ clear: 'both' }}>
      <AddressCountryDropdown
        addressRegion={addressRegion}
        onChange={onChangeAddressRegion}
      />
      <div className={commonStyles.col_8}>
        <AutoSuggest
          label="Personal address"
          value={addressKeyword}
          loading={deliveryAddressLoading}
          suggest_items={deliveryAddressOptions || []}
          handleChange={handleDeliveryAddressChange}
          handleClick={handleDeliveryAddressClick}
          required={true}
          css_class="address_lookup"
          error={get(errors, 'address', '')}
        />
      </div>
    </div>
  );
}

export default AddressPicker;
