import { AxiosInstance, AxiosRequestConfig } from 'axios';
import axios from './axios';
import {
  IRequesterAttribute,
  IRequesteesAttribute,
} from 'types/authorisationRequest';

export default class OnboardingEntityDetails {
  axios: AxiosInstance;

  type: string;

  path: string;

  constructor(config: AxiosRequestConfig | undefined) {
    this.axios = axios(config);
    this.type = 'onboarding/entity_details';

    this.axios.defaults.baseURL = this.axios.defaults.baseURL!.replace(
      'v1',
      'v2'
    );

    this.path = `/${this.type}`;
  }

  fetchAuthorisationRequestByApplication({
    applicationId,
  }: {
    applicationId: string;
  }): Promise<void> {
    return this.axios.get(
      `${this.path}/fetch_authorisation_request_by_application`,
      {
        params: { application_id: applicationId },
      }
    );
  }

  fetchAuthorisationRequestByConsumerAndRequester({
    applicationId,
    consumerId,
    requesterId,
  }: {
    applicationId: string;
    consumerId: string;
    requesterId: string;
  }): Promise<void> {
    return this.axios.get(`${this.path}/authorisation_request`, {
      params: {
        application_id: applicationId,
        consumer_id: consumerId,
        requester_id: requesterId,
      },
    });
  }

  updateAuthorisationRequest({
    applicationId,
    consumerId,
    requestees,
    requester,
  }: {
    applicationId: string;
    consumerId: string;
    requestees: IRequesteesAttribute[];
    requester: IRequesterAttribute;
  }): Promise<void> {
    return this.axios.post(`${this.path}/authorisation_request`, {
      application_id: applicationId,
      consumer_id: consumerId,
      requestees,
      requester,
    });
  }
}
