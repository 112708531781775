/* eslint-disable max-lines */
import debounce from 'debounce';
import get from 'lodash.get';
import ModuleCardholderApplicationModel from 'models/ModuleCardholderApplicationModel';
import UserModel from 'models/UserModel';
import styles from 'modules/card-management-applications/css/CardOrdersIndex.css';
import commonStyles from 'modules/direct-debit-authority/css/DirectDebitAuthorityIndex.css';
import NumApplicationsBlock from 'modules/reporting/components/NumApplicationsBlock';
import DateInput from 'modules/shared/components/inputs/DateInput';
import TextInput from 'modules/shared/components/inputs/TextInput';
import TierOptions from 'modules/shared/components/v2/TierOptions';
import OptionsDropdown from 'modules/shared/components/widgets/interactive/OptionsDropdown';
import Pagination from 'modules/shared/components/widgets/interactive/Pagination';
import AdminInnerTitle from 'modules/shared/components/widgets/static/AdminInnerTitle';
import StatusIndicator from 'modules/shared/components/widgets/static/StatusIndicator';
import { USER_UPDATE_PREFERENCE_SUCCESS } from 'modules/user/constants';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { formatLocalTime } from 'utils/dateFormatter';
import isPresent from 'utils/isPresent';

const DEFAULT_PER_PAGE = 25;
const FILTER_PERIODS = [
  {
    callback: () => {
      return { from: moment('2016-11-25'), to: moment().endOf('day') };
    },
    label: 'All',
    value: 'All',
  },
  {
    callback: () => {
      return { from: moment().startOf('day'), to: moment().endOf('day') };
    },
    label: 'Today',
    value: 'Today',
  },
  {
    callback: () => {
      return {
        from: moment()
          .subtract(1, 'days')
          .startOf('day'),
        to: moment()
          .subtract(1, 'days')
          .endOf('day'),
      };
    },
    label: 'Yesterday',
    value: 'Yesterday',
  },
  {
    callback: () => {
      return {
        from: moment().startOf('week'),
        to: moment().endOf('day'),
      };
    },
    label: 'This week',
    value: 'This week',
  },
  {
    callback: () => {
      return {
        from: moment().subtract(7, 'days'),
        to: moment().endOf('day'),
      };
    },
    label: 'Last 7 days',
    value: 'Last 7 days',
  },
  {
    callback: () => {
      return {
        from: moment().subtract(30, 'days'),
        to: moment().endOf('day'),
      };
    },
    label: 'Last 30 days',
    value: 'Last 30 days',
  },
  {
    callback: () => {
      return {
        from: moment().subtract(60, 'days'),
        to: moment().endOf('day'),
      };
    },
    label: 'Last 60 days',
    value: 'Last 60 days',
  },
  {
    callback: () => {
      return {
        from: moment().subtract(90, 'days'),
        to: moment().endOf('day'),
      };
    },
    label: 'Last 90 days',
    value: 'Last 90 days',
  },
  {
    label: 'Custom date range',
    value: 'Custom date range',
  },
];

function useApplicationsState(props) {
  const { currentUser } = props;
  const [applications, setApplications] = useState([]);
  const [meta, setMeta] = useState({});
  const [applicationParams, setApplicationParams] = useState({
    active_tab: 'started',
    card_issuing_reason: 'All',
    from: moment('2016-11-25'),
    page: 1,
    per: get(
      currentUser,
      'preferenceByCurrentEntity.cardManagementApplicationsPerPage',
      DEFAULT_PER_PAGE
    ),
    search: null,
    to: moment().endOf('day'),
  });

  const onFetchModuleCardholderApplications = (params) => {
    (async () => {
      const newParams = { ...applicationParams, ...params };
      const {
        meta,
        moduleCardholderApplications,
      } = await ModuleCardholderApplicationModel.fetchModuleCardholderApplications(
        {
          accessToken: currentUser.accessToken,
          entityId: get(currentUser, 'currentEntity.id'),
          params: newParams,
        }
      );
      setApplicationParams(newParams);
      setApplications(moduleCardholderApplications);
      setMeta(meta);
    })();
  };

  return {
    applicationParams,
    applications,
    meta,
    onFetchModuleCardholderApplications,
  };
}

function StatisticsBlock(props) {
  const { activeBlock, meta, onClickBlock } = props;
  const onClick = (block) => onClickBlock({ active_tab: block, page: 1 });

  return (
    <div className={commonStyles.statistics_block}>
      <NumApplicationsBlock
        color="blue"
        title="Started"
        value={meta.total || 0}
        active={activeBlock === 'started'}
        isCompact={true}
        onClick={() => onClick('started')}
      />

      <NumApplicationsBlock
        color="grey"
        title="Incomplete"
        value={meta.incomplete || 0}
        active={activeBlock === 'incomplete'}
        isCompact={true}
        onClick={() => onClick('incomplete')}
      />

      <NumApplicationsBlock
        color="blue"
        title="Complete"
        value={meta.completed || 0}
        active={activeBlock === 'completed'}
        isCompact={true}
        onClick={() => onClick('completed')}
      />

      <NumApplicationsBlock
        color="green"
        title="Loaded in system"
        value={meta.loaded_in_system || 0}
        active={activeBlock === 'loaded_in_system'}
        isCompact={true}
        onClick={() => onClick('loaded_in_system')}
      />

      <NumApplicationsBlock
        color="medium-grey"
        title="Archive"
        value={meta.archived || 0}
        active={activeBlock === 'archived'}
        isCompact={true}
        onClick={() => onClick('archived')}
      />
    </div>
  );
}

function CustomPeriodFilter(props) {
  const {
    applicationParams,
    isVisible,
    onFetchModuleCardholderApplications,
  } = props;

  if (!isVisible) {
    return null;
  }

  const onChangeDateFrom = (value) =>
    onFetchModuleCardholderApplications({ from: moment(value, 'DD/MM/YYYY') });
  const onChangeDateTo = (value) =>
    onFetchModuleCardholderApplications({ to: moment(value, 'DD/MM/YYYY') });

  return (
    <Fragment>
      <DateInput
        id="from"
        name="from"
        label="Custom date from"
        value={moment(applicationParams.from, 'DD/MM/YYYY').format(
          'DD/MM/YYYY'
        )}
        required={true}
        onChange={onChangeDateFrom}
      />
      <DateInput
        id="to"
        name="to"
        label="Custom date to"
        value={moment(applicationParams.to, 'DD/MM/YYYY').format('DD/MM/YYYY')}
        required={true}
        onChange={onChangeDateTo}
      />
    </Fragment>
  );
}

function PeriodFilter(props) {
  const { onFetchModuleCardholderApplications } = props;
  const [selectedPeriod, setSelectedPeriod] = useState('All');
  const onChangePeriod = (selectedOption) => {
    const { callback, value } = selectedOption;

    if (callback) {
      const { from, to } = callback();
      onFetchModuleCardholderApplications({ from, to });
    }

    setSelectedPeriod(value);
  };

  return (
    <Fragment>
      <OptionsDropdown
        label="Period"
        required={true}
        value={selectedPeriod}
        options={FILTER_PERIODS}
        handleChange={onChangePeriod}
      />
      <CustomPeriodFilter
        isVisible={selectedPeriod === 'Custom date range'}
        {...props}
      />
    </Fragment>
  );
}

const sanitizeCardIssuingReasonLabel = (reason) => {
  if (isPresent(reason)) {
    return reason.replace('card', '').trim();
  }

  return reason;
};

function CardIssuingReasonFilter(props) {
  const { meta, onFetchModuleCardholderApplications } = props;
  const [selectedCardIssuingReason, setSelectedCardIssuingReason] = useState(
    'All'
  );
  const options = [{ label: 'All', value: 'All' }];
  const reasons = meta.card_issuing_reasons || [];
  for (const reason of reasons) {
    options.push({
      label: sanitizeCardIssuingReasonLabel(reason),
      value: reason,
    });
  }

  const onChange = (selectedOption) => {
    const { value } = selectedOption;

    onFetchModuleCardholderApplications({ card_issuing_reason: value });
    setSelectedCardIssuingReason(value);
  };

  return (
    <OptionsDropdown
      label="Issuing reason"
      required={true}
      value={selectedCardIssuingReason}
      options={options}
      handleChange={onChange}
    />
  );
}

/**
 * TODO: Commented out as not required by Farmlands but keeping in here just in
 * case needed in the future
 */
// function Export(props) {
//   const { applicationParams, currentUser } = props;
//   const [isExporting, setIsExporting] = useState(false);
//   const onExport = async selectedOption => {
//     setIsExporting(true);

//     const moduleCardholderApplicationAPI = api(
//       "module_cardholder_applications",
//       currentUser.accessToken,
//       get(currentUser, "currentEntity.id"),
//     );

//     try {
//       const result = await moduleCardholderApplicationAPI.export(
//         applicationParams,
//       );
//       const timestamp = moment()
//         .toDate()
//         .toISOString();
//       const fileType = selectedOption.value;
//       const filename = `1centre-card-order-forms-${timestamp}.${fileType}`;
//       exportXlsxCSV(get(result, "data.data", []), filename);
//     } catch (error) {
//       console.error(error);
//     } finally {
//       setIsExporting(false);
//     }
//   };

//   return (
//     <OptionsDropdown
//       id="export"
//       handleChange={onExport}
//       label={isExporting ? "Exporting" : "Export File"}
//       options={[
//         { label: "Excel/xlsx", value: "xlsx" },
//         { label: "CSV/csv", value: "csv" },
//       ]}
//       value=""
//       disabled={isExporting}
//     />
//   );
// }

function Filter(props) {
  const {
    applicationParams,
    meta,
    onFetchModuleCardholderApplications,
  } = props;
  const [search, setSearch] = useState(applicationParams.search);

  const onChangeSearch = (event) => {
    const value = get(event, 'target.value');

    setSearch(value);
    debounce(onFetchModuleCardholderApplications({ search: value }), 1000);
  };

  const resetSearch = () => {
    setSearch('');
    debounce(onFetchModuleCardholderApplications({ search: '' }), 1000);
  };

  return (
    <div className={commonStyles.filter_container}>
      <TextInput
        id="search"
        label="Search"
        required={true}
        value={search}
        onChange={onChangeSearch}
        resetF={resetSearch}
      />
      <TierOptions
        onFetchApplication={onFetchModuleCardholderApplications}
        tierOptions={meta.tier_options || []}
      />
      <PeriodFilter {...props} />
      <CardIssuingReasonFilter {...props} />
    </div>
  );
}

function formatDate(date) {
  if (isPresent(date)) {
    return formatLocalTime(date, 'DD/MM/YYYY');
  }

  return '-';
}

function ApplicationTableHeader() {
  return (
    <div className={`${styles.grid_table} ${commonStyles.table_header}`}>
      <div>Account name</div>
      <div>Account number</div>
      <div>Tier</div>
      <div>Issuing reason</div>
      <div>Status</div>
      <div>Started</div>
      <div>Completed</div>
    </div>
  );
}

function ApplicationRow(props) {
  const { application } = props;

  return (
    <div className={styles.grid_table}>
      <div className={commonStyles.account_name}>
        <Link to={`/dashboard/card_orders/${application.id}`}>
          {application.accountName}
        </Link>
      </div>
      <div>{application.accountNumber}</div>
      <div>{application.supplierName}</div>
      <div>{sanitizeCardIssuingReasonLabel(application.cardIssuingReason)}</div>
      <div>
        <StatusIndicator
          status={application.formattedStatus}
          withStatusName={true}
          green_text={
            application.isLoadedInSystem ? 'loaded in system' : 'complete'
          }
        />
      </div>
      <div>{formatDate(application.createdAt)}</div>
      <div>{formatDate(application.completedAt)}</div>
    </div>
  );
}

function ApplicationTable(props) {
  const { applications } = props;

  const rows = applications.map((application, index) => (
    <ApplicationRow key={`application-${index}`} application={application} />
  ));

  return (
    <div className={commonStyles.table_content}>
      <ApplicationTableHeader />
      {rows}
    </div>
  );
}

function CardOrdersIndex(props) {
  const {
    applicationParams,
    applications,
    meta,
    onFetchModuleCardholderApplications,
  } = useApplicationsState(props);
  const { currentUser, dispatch } = props;

  useEffect(() => onFetchModuleCardholderApplications(), []);

  const onClickPage = (page) => onFetchModuleCardholderApplications({ page });

  const onClickPerPage = (per) => {
    currentUser.updatePreferences({ cardOrdersPerPage: per });
    dispatch({
      payload: currentUser.attributes.preference,
      type: USER_UPDATE_PREFERENCE_SUCCESS,
    });
    onFetchModuleCardholderApplications({ page: 1, per });
  };

  return (
    <div className={commonStyles.container}>
      <div className={commonStyles.header_container}>
        <div className={commonStyles.section}>
          <div className={commonStyles.header_title}>
            <AdminInnerTitle text="Cards" isNormalFont={true} />
          </div>
          <StatisticsBlock
            meta={meta}
            activeBlock={applicationParams.active_tab}
            onClickBlock={onFetchModuleCardholderApplications}
          />
          <Filter
            applicationParams={applicationParams}
            meta={meta}
            onFetchModuleCardholderApplications={
              onFetchModuleCardholderApplications
            }
            {...props}
          />
        </div>
      </div>

      <div className={commonStyles.table_container}>
        <div className={commonStyles.section}>
          <ApplicationTable
            applications={applications}
            meta={meta}
            {...props}
          />
          <Pagination
            dataLength={meta.total}
            currentPage={applicationParams.page}
            todosPerPage={applicationParams.per}
            resourceName="Entries"
            optionViewPage={[
              { label: '25', value: DEFAULT_PER_PAGE },
              { label: '50', value: 50 },
            ]}
            handleClick={onClickPage}
            handleClickTodosPerPage={onClickPerPage}
          />
        </div>
      </div>
    </div>
  );
}

export default connect((state) => {
  return {
    currentUser: UserModel.fromCurrentUser(state.current_user),
  };
})(CardOrdersIndex);
